import React from "react";
import { useSelector } from "react-redux";
import useMediaQuery from "@restart/hooks/cjs/useMediaQuery";
import { SECTION_IDS, SELECTORS } from "@Utils";
import { classNames } from "repoV2/utils/common/render/classNames";
import { ButtonV2 } from "@Modules/common/ButtonV2";
import ExlyImage from "repoV2/features/Common/modules/ExlyImage/ExlyImage";
import {
    TEMPLATE_CONFIG,
    TEMPLATE_KEYS,
} from "repoV2/features/CreatorPages/CreatorPages.constants";
import { IMainpage } from "./Mainpage";
import { Button } from "../button";
import style from "./Mainpage.module.scss";

export const MainPage = ({
    getInTouchCtaText,
    onGetInTouchClick,
    preview,
    handlePreviewPopup,
    hostData,
}: IMainpage.IProps): JSX.Element => {
    const plansData = useSelector(SELECTORS.plansData);
    const isDesktopOrLaptop: boolean = useMediaQuery(
        "(min-device-width: 769px)"
    );
    const defaultCoverPic: string =
        "/static/images/modernTemplate/cover_pic.jpeg";

    const handleNavigate = (e: React.MouseEvent): boolean => {
        if (preview) {
            e.preventDefault();
            handlePreviewPopup();
            return false;
        }
        return true;
    };

    // Hide the button on mobile OR a hide flag in metadata is set to true
    const hideGetInTouch: boolean =
        !!hostData?.hide_get_in_touch_section || !isDesktopOrLaptop;

    const areEventsAvailable: boolean =
        [...(hostData?.listings || []), ...(plansData?.list || [])].length > 0;

    // If there is no content on the hero section, do not show the overlay shadow
    // If either of the four elements are present the condition below will turn to true
    // The !! in the beginning just enforces the boolean type
    const showDarkOverlay: boolean = !!(
        !hideGetInTouch ||
        (hostData?.global_booking_cta && areEventsAvailable) ||
        hostData?.title ||
        hostData?.short_description
    );

    return (
        <div className={style.mainPageContainer}>
            <div
                className={classNames(
                    style.pageContainer,
                    showDarkOverlay && style.darken
                )}
            >
                <ExlyImage
                    fetchWidth={
                        TEMPLATE_CONFIG[TEMPLATE_KEYS.MODERN].coverImage.width
                    }
                    width={
                        TEMPLATE_CONFIG[TEMPLATE_KEYS.MODERN].coverImage.width
                    }
                    src={hostData?.cover_pic || defaultCoverPic}
                    className={style.coverPic}
                    alt="coverPic"
                />
                <div className={style.containerData}>
                    {(hostData?.title && (
                        <h1 className={style.heading1}>
                            {hostData.title.slice(0, 50)}
                        </h1>
                    )) ||
                        (preview && (
                            <div className={style.heading1}>
                                {"<Your page title will be displayed here>"}
                            </div>
                        )) ||
                        null}
                    {(hostData?.short_description && (
                        <div className={style.heading2}>
                            {hostData?.short_description.slice(0, 101)}
                        </div>
                    )) ||
                        (preview && (
                            <div className={style.heading2}>
                                {"<Your short bio will be displayed here>"}
                            </div>
                        )) ||
                        null}
                    <div className={style.buttonBlock}>
                        {/* Set global_booking_cta to an empty string to hide the button */}
                        {hostData?.global_booking_cta && areEventsAvailable ? (
                            <a
                                role="button"
                                className={style.bookButton}
                                href={`#${SECTION_IDS.eventSections}`}
                                onClick={handleNavigate}
                            >
                                <Button
                                    buttonName={
                                        hostData?.global_booking_cta ||
                                        "Book a Spot"
                                    }
                                />
                            </a>
                        ) : null}
                        {hideGetInTouch ? null : (
                            <ButtonV2
                                design="custom"
                                className={style.getButton}
                                onClick={() => {
                                    onGetInTouchClick();
                                }}
                            >
                                {getInTouchCtaText}
                            </ButtonV2>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { isDefined } from "repoV2/utils/common/dataTypes/common";
import {
    EVENT_CATEGORISATION_TYPE,
    SECTION_IDS,
    useEventCategorisationKeywords,
} from "@Utils";
import { Keywords, LoadingSpinner } from "@Modules/common";
import { ICategorisedEventList } from "@Modules/hostPage/CategorisedEventList/ICategorisedEventList";
import { CategorisedEventList } from "@Modules/hostPage";
import { ITemplate } from "@Templates/ITemplate";
import { BrowseCard } from "../browseCard";
import style from "./BrowseSection.module.scss";

const EventSectionsContainer: ICategorisedEventList["EventSectionsContainer"] =
    ({ children }) => {
        return (
            <div className={style.browseSectionContainer}>
                <a
                    id={SECTION_IDS.eventSections}
                    className={style.anchorBrowse}
                />
                {children}
            </div>
        );
    };

export const BrowseSection = ({
    eventListings,
    allKeywords,
    sectionTitle,
    onEventListingClick,
    allEventCategories,
    categoriseType,
    onShareClick,
    onHeartClick,
    showSkuLabelAsTag,
    template,
}: ITemplate.IEventSectionsProps) => {
    // Size of each batch to show when the infinite loader loads more items
    const STEP_SIZE = 12;
    const [dataLen, setDataLen] = useState<number>(STEP_SIZE);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const slicedEventList =
        categoriseType === EVENT_CATEGORISATION_TYPE.NO_CATEGORISATION
            ? eventListings.slice(0, dataLen)
            : eventListings;

    useEffect(() => {
        setHasMore((eventListings?.length ?? 0) > STEP_SIZE);
    }, [eventListings?.length]);

    const handleLoadData = () => {
        if (dataLen < eventListings.length) {
            setTimeout(() => {
                setDataLen(dataLen + STEP_SIZE);
            }, 500);
        } else setHasMore(false);
    };

    const EventCards = ({
        eventListProp,
    }: {
        eventListProp: Array<ITemplate.IEventListingData>;
    }) => {
        return (
            <section className={style.cards}>
                {eventListProp.map(event => (
                    <BrowseCard
                        data={event}
                        key={event.plan_uuid || event.uuid}
                        onShareClick={onShareClick}
                        onHeartClick={onHeartClick}
                        showSkuLabelAsTag={showSkuLabelAsTag}
                        onEventListingClick={onEventListingClick}
                    />
                ))}
            </section>
        );
    };

    const EventSectionTitle = ({
        eventSectionTitle,
    }: {
        eventSectionTitle: string;
    }) => {
        return eventSectionTitle ? (
            <div className={style.browseSectionTitle}>
                <span className={style.browseTitle}>{eventSectionTitle}</span>
                <span className={style.browseArrow}>
                    <svg
                        width="35"
                        height="35"
                        className={style.arrow}
                        viewBox="0 0 55 55"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M54.5298 26.2754L38.0307 7.94311C37.6787 7.55446 37.1874 7.33447 36.6667 7.33447H25.6673C24.945 7.33447 24.2887 7.75978 23.9918 8.41975C23.6984 9.08338 23.8194 9.857 24.3034 10.3923L39.6989 27.5L24.3034 44.6041C23.8194 45.1431 23.6948 45.9167 23.9918 46.5767C24.2887 47.2403 24.945 47.6656 25.6673 47.6656H36.6667C37.1874 47.6656 37.6787 47.442 38.0307 47.0607L54.5298 28.7283C55.1567 28.0317 55.1567 26.9684 54.5298 26.2754Z"
                            fill="#ED4853"
                        />
                        <path
                            d="M30.6977 26.2754L14.1986 7.94311C13.8466 7.55446 13.3553 7.33447 12.8347 7.33447H1.8353C1.113 7.33447 0.456704 7.75978 0.15972 8.41975C-0.133598 9.08338 -0.0126043 9.857 0.471369 10.3923L15.8669 27.5L0.471369 44.6041C-0.0126043 45.1431 -0.137264 45.9167 0.15972 46.5767C0.456704 47.2403 1.113 47.6656 1.8353 47.6656H12.8347C13.3553 47.6656 13.8466 47.442 14.1986 47.0607L30.6977 28.7283C31.3247 28.0317 31.3247 26.9684 30.6977 26.2754Z"
                            fill="#ED4853"
                        />
                    </svg>
                </span>
            </div>
        ) : null;
    };

    const EventSection: ICategorisedEventList["EventSection"] = ({
        eventListProp,
        index,
        category,
        showKeywords,
    }) => {
        const {
            filteredEventList,
            loading,
            keywords,
            onKeywordClick,
            selectedKeyword,
        } = useEventCategorisationKeywords(eventListProp, allKeywords);

        if (categoriseType === EVENT_CATEGORISATION_TYPE.NO_CATEGORISATION) {
            return (
                <>
                    <EventSectionTitle eventSectionTitle={category} />
                    <div className={style.cardSection}>
                        <InfiniteScroll
                            dataLength={dataLen}
                            next={handleLoadData}
                            hasMore={hasMore}
                            loader={
                                // eslint-disable-next-line react/jsx-wrap-multilines
                                <div
                                    className={style.loader}
                                    style={{ textAlign: "center" }}
                                />
                            }
                        >
                            <EventCards
                                eventListProp={
                                    hasMore
                                        ? filteredEventList.slice(
                                              0,
                                              slicedEventList.length
                                          )
                                        : filteredEventList
                                }
                            />
                        </InfiniteScroll>
                    </div>
                </>
            );
        }

        return (
            <>
                {isDefined(index) ? (
                    <a
                        id={SECTION_IDS.eventSection(index!)}
                        className={style.anchorEventSection}
                    />
                ) : null}
                <EventSectionTitle eventSectionTitle={category} />
                {showKeywords ? (
                    <Keywords
                        keywords={keywords}
                        selectedKeyword={selectedKeyword}
                        keywordClick={onKeywordClick}
                        template={template}
                    />
                ) : null}
                {loading ? (
                    <div className={style.loadingSpinner}>
                        <LoadingSpinner small />
                    </div>
                ) : (
                    <div className={style.cardSection}>
                        <EventCards eventListProp={filteredEventList} />
                    </div>
                )}
            </>
        );
    };

    return eventListings.length > 0 ? (
        <CategorisedEventList
            eventSectionsTitle={sectionTitle}
            EventSectionsContainer={EventSectionsContainer}
            EventSection={EventSection}
            allCategories={allEventCategories}
            eventListings={eventListings}
            categoriseType={categoriseType}
        />
    ) : null;
};

/* eslint-disable no-nested-ternary */
import React from "react";
import { API_ACTION_TYPES, FETCH_STATUS, useFetchStatus } from "@Utils";
import { classNames } from "repoV2/utils/common/render/classNames";

import { ButtonV2 } from "@Modules/common/ButtonV2";
import { parseDateTime } from "@Utils/parseDateTime";
import { ISlotBookCard } from "./ISlotBookCard";

import style from "./slotBookCard.module.scss";

export const SlotBookCard = ({
    eventData,
    isPriceFetched,
}: ISlotBookCard.IProps): JSX.Element => {
    const period = eventData?.next_slot_time
        ? new Date(eventData.next_slot_time).getTime() > Date.now()
        : null;
    const [date, month] = period
        ? parseDateTime(eventData.next_slot_time!, "dd;MMM").split(";")
        : ["", ""];
    const isValid: boolean = /^₹ [0-9]+(\.[0-9]+)*$/.test(
        eventData?.short_description
    );
    const fetchStatus = useFetchStatus(API_ACTION_TYPES.FETCH_USER_IP);

    return (
        <div className={style.slotBookContainer}>
            <div className={style.slotTitle}>
                <div className={style.slotTitle1}>{eventData.title}</div>
                {period ? (
                    <div className={style.period}>
                        {date}
                        &nbsp;
                        {month}
                    </div>
                ) : null}
            </div>
            <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                    __html: `<span>&nbsp;</span>${
                        (!isValid && eventData?.short_description) || ""
                    }`,
                }}
                className={style.subTitle}
            />
            <div
                className={classNames(style.bookContainer, style.buttonMargin)}
            >
                <div className={style.bookSlot}>
                    <ButtonV2 applyTheme className={style.ctaButton}>
                        {eventData.ctaText}
                    </ButtonV2>
                </div>
                <div className={style.amt}>
                    {isPriceFetched ||
                    fetchStatus === FETCH_STATUS.FETCH_ERROR ? (
                        eventData?.hidePrice ? (
                            <>&nbsp;</>
                        ) : (
                            <span>
                                {eventData?.currency}
                                {Math.round(eventData?.updated_price)}
                            </span>
                        )
                    ) : (
                        <div className={style.shimmer} />
                    )}
                </div>
            </div>
        </div>
    );
};

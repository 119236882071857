import React from "react";
import { useSelector } from "react-redux";
import { IHost } from "@Interfaces";
import { MEDIA, SELECTORS, colorByListingType } from "@Utils";
import ExlyImage from "repoV2/features/Common/modules/ExlyImage/ExlyImage";
import { SlotBookCard } from "../slotBookCard";
import { IBrowseCard } from "./BrowseCard";
import style from "./BrowseCard.module.scss";

export const BrowseCard = ({
    data,
    onShareClick: onShareClickProp,
    showSkuLabelAsTag = false,
    onEventListingClick,
    onHeartClick: onHeartClickProp,
}: IBrowseCard.IProps): JSX.Element => {
    const heart = MEDIA.DEFAULT.CARD_HEART;
    const share = MEDIA.DEFAULT.CARD_SHARE;
    const host: IHost.IStore = useSelector(SELECTORS.host);

    const onHeartClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        onHeartClickProp("id" in data ? data.id : undefined);
    };

    const onShareClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        onShareClickProp(data);
    };

    const imgUrl: string = data?.cover_image || MEDIA.DEFAULT.CARD_IMAGE;

    return (
        <div
            className={style.cardOuterContainer}
            onClick={() => onEventListingClick(data)}
        >
            <div className={style.cardContainer}>
                <div className={style.cardImgContainer}>
                    <ExlyImage
                        src={imgUrl}
                        alt={data?.title}
                        loading="lazy"
                        fetchWidth={750}
                        width={500}
                        height={500}
                    />
                </div>
                <div className={style.cardImgContent}>
                    {showSkuLabelAsTag ? (
                        <span
                            className={style.leftContent}
                            style={{
                                backgroundColor: colorByListingType(
                                    data.type,
                                    "isPlan" in data && data?.isPlan
                                ),
                            }}
                        >
                            {("sku_title" in data && data.sku_title) ||
                                ("listingTypeTitle" in data &&
                                    data.listingTypeTitle)}
                        </span>
                    ) : null}
                    <span className={style.rightContent}>
                        <img
                            className={style.heartIcon}
                            src={heart}
                            role="presentation"
                            alt="heartIcon"
                            onClick={onHeartClick}
                            loading="lazy"
                        />
                        <img
                            className={style.shareIcon}
                            src={share}
                            role="presentation"
                            alt="share"
                            onClick={onShareClick}
                            loading="lazy"
                        />
                    </span>
                    {data?.metadata?.card_label ? (
                        <div className={style.eventTagView}>
                            <div
                                className={style.tagTitleStyle}
                                style={{
                                    backgroundColor:
                                        data?.metadata?.card_label
                                            ?.background || "black",
                                    color:
                                        data?.metadata?.card_label?.color ||
                                        "white",
                                }}
                            >
                                {data?.metadata?.card_label.content}
                            </div>
                            <div
                                className={style.triangleCorner}
                                style={{
                                    borderTopColor:
                                        data?.metadata?.card_label.background ||
                                        "black",
                                }}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="contentCard">
                <SlotBookCard
                    eventData={data}
                    isPriceFetched={!!host.user_ip}
                />
            </div>
        </div>
    );
};

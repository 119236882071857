import React from "react";
import { IButton } from "./Button";

import style from "./Button.module.scss";

export const Button: React.FunctionComponent<IButton.IProps> = ({
    buttonName,
}: IButton.IProps) => {
    // TODO: Phase out eventually and replace with ButtonV2
    return (
        <div className={style.buttonContainer}>
            <button type="button" className={style.button}>
                {buttonName}
            </button>
        </div>
    );
};
